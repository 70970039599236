import React, { useState } from 'react';
import '../design/jumpbox.css';

const FloatingJumpToPage = ({ currentPage, numPages, onJumpButtonClick }) => {
    const [jumpToPage, setJumpToPage] = useState(currentPage);
    const handleJumpButtonClick = () => {
        onJumpButtonClick(jumpToPage);
    };

    return (
        <div className="floating-jump-box">
            <span>{"Page " + currentPage + " / " + numPages}</span>
            <br />
            <span>{"Jump to: " }</span>
            <input
                className="page-number"
                type="text"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)} />
            <button className="jump-button" id="jump" onClick={handleJumpButtonClick}>Go</button>
        </div>
    );
};

export default FloatingJumpToPage;
