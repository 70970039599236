import './App.css';
import PDFViewer from './components/PDFViewer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import BooksGallery from './components/BooksGallery';

function App() {
    const pdfUrl = '/AndroidDevelopers.pdf';

    const handleGlobalKeyPress = (event) => {
        if (event.key === 'Enter') {
            const buttonToClick = document.getElementById('jump');
            if (buttonToClick) {
                buttonToClick.click();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleGlobalKeyPress);
        return () => {
            window.removeEventListener('keydown', handleGlobalKeyPress);
        };
    }, []);

    const books = [
        {
            id: 'android_developers',
            title: 'Android Developers',
            author: 'by Roei Amiga',
            price: '20 ₪',
            image: 'AndroidBookCover2.png',
            url: '/android_developers',
            //description: 'Learn android from scratch',
        },
        // Add more book objects
    ];

    return (
        <Router>
            <Routes>
                <Route path="/gallery" element={
                    <BooksGallery books={books} />
                } />
                <Route path="/android_developers" element={
                    <PDFViewer pdfUrl={pdfUrl} bookId={books[0].id} />
                } />
                {/* Other routes */}
            </Routes>
        </Router>
    );
}

export default App;
