import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import axios from 'axios';
import '../design/paypal.css';
import { db } from './MyFirebase';
import { ref, set } from 'firebase/database';

const PayPalComponent = () => {

    const [accessToken, setAccessToken] = useState('');

    // PayPal API TEST
    //const payPalSiteAddress = 'https://api.sandbox.paypal.com';

    // PayPal API PRODUCTION
    const payPalSiteAddress = 'https://api-m.paypal.com';

    // PayPal API TEST credentials
    /*const clientId = 'AfG20YqJ54n17vaNRpSE4tB1mX0WEIOQYO72CYZjOkj4d1Y45wWk2Nacw7T33_3BiZyidbLXfPVyv8aq';
    const clientSecret = 'ED281AYTqwcF7dP-hkyGhFmw_1UFc0OhzBwyTkzrLowvYSCAamB-yWpRUXMrwxysm4Wy1w7dP1UGyUsK';*/

    // PayPal API PRODUCTION credentials
    const clientId = 'AV-t50LsOg6OhD6_s6lpgpTezVDk2xplX_w5CcSTLhRt6DljR3fSH7yOyFYiZA-rN_fg7c5RZCIWao6t';
    const clientSecret = 'EOjB_Nm2pQBQgez26coRCZ7GZYAPcYOBdYlhizbeA--Fr5bF1o7F6OxAfb8E37WEMCaJKfZ3NXRiTgB5';

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {

                // Base64 encode the credentials
                const credentials = btoa(`${clientId}:${clientSecret}`);

                // PayPal API endpoint
                const tokenEndpoint = `${payPalSiteAddress}/v1/oauth2/token`;

                // Request headers
                const headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${credentials}`,
                };

                // Request payload
                const payload = 'grant_type=client_credentials';

                // Make the request
                await axios
                    .post(tokenEndpoint, payload, { headers })
                    .then(response => {
                        const token = response.data.access_token;
                        setAccessToken(token);
                    })
                    .catch(error => {
                        console.error('Error fetching access token:', error);
                    });

            } catch (error) {
                console.error('Error fetching access token:', error);
                // Handle the error or display an appropriate error message
            }
        };

        fetchAccessToken();

    }, []);

    const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];

    const initialOptions = {
        'client-id': `${clientId}`, // Replace with your actual client ID
        'currency': 'ILS', // Specify the currency for PayPal payments
    };

    const createOrderHandler = async (data, actions) => {

        const customItemId = 'Android-eBook'; // Replace with your item's custom identifier

        // Check if the custom identifier exists in your system
        const isDuplicatePurchase = await checkIfDuplicatePurchase(customItemId);

        if (isDuplicatePurchase) {
            alert('You have already purchased this item.');
            // Additional logic to handle duplicate purchase
            return;
        }

        try {
            const response = await axios.post(`${payPalSiteAddress}/v2/checkout/orders`, {
                intent: "CAPTURE",
                purchase_units: [
                    {
                        amount: {
                            currency_code: "ILS",
                            value: "20.00",
                        },
                        custom_id: "Android_eBook", // Unique identifier for your item
                    },
                ],
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                },
            });
            const order = await response.data;
            return order.id;
        } catch (error) {
            console.error("Error creating PayPal order:", error);
            // Handle the error or display an appropriate error message to the user
        }
    };

    /*const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // Month is zero-based, so add 1
        const day = now.getDate();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    };*/

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // Month is zero-based, so add 1
        const day = now.getDate();

        const formattedDateTime = `${year}-${month}-${day}`;
        return formattedDateTime;
    };

    const getNextYearDate = () => {
        const now = new Date();
        const year = now.getFullYear() + 1;
        const month = now.getMonth() + 1; // Month is zero-based, so add 1
        const day = now.getDate();

        const formattedDateTime = `${year}-${month}-${day}`;
        return formattedDateTime;
    };

    const handlePurchase = (orderID) => {
        //setPurchasedItems(prevItems => [...prevItems, orderID]);

        // Access the user data (Session)
        const storedUser = sessionStorage.getItem('user');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const customItemId = 'android_developers'; // Replace with your item's custom identifier
        
        // Check if the user is authenticated
        if (user) {

            const booksRef = ref(db, `users/${user.id}/purchasedBooks/${customItemId}`); // Reference to the 'books' node in the database

            const newBook = {
                purchaseDate: getCurrentDate(),
                expirationDate: getNextYearDate(),
                // ...other book details
            };

            set(booksRef, newBook) // This will generate a unique key and add the book data
                .then(() => {
                    console.log('Book added successfully');
                })
                .catch((error) => {
                    console.error('Error adding book:', error);
                });

                
        }
        else {
            //window.location.reload();
        }
    };

    const onApproveHandler = async (data, actions) => {
        try {
            const orderID = data.orderID;

            const response = await fetch(`${payPalSiteAddress}/v2/checkout/orders/${orderID}/capture`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                },
            });

            const details = await response.json();

            if (details.status === 'COMPLETED') {
                handlePurchase(orderID); // Mark the item as purchased
                alert('Payment was successful! Thank you for your purchase.');
                // Additional logic to show user that the payment was successful
            } else {
                alert('Payment failed. Please try again later.');
                // Additional logic to handle failed payment
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred during payment. Please try again later.');
            // Additional logic to handle error during payment
        }
    };

    // Function to check if the custom identifier exists in your system
    const checkIfDuplicatePurchase = (customItemId) => {
        // Implement your logic to check if the custom identifier exists in your system
        // Return true if it's a duplicate, false if it's not
    };

    // Wait until accessToken is available before rendering
    if (!accessToken) {
        return <div>Loading...</div>;
    }
    else {
        console.log(`Access token available`);
    }
    /*else    return (
        // Your component JSX using the accessToken
    );*/

    return (
        <div>
            <PayPalScriptProvider options={initialOptions}>
                {
                    FUNDING_SOURCES.map(fundingSource => {
                        return (
                            <PayPalButtons
                                className="custom-paypal-button"
                                fundingSource={fundingSource}
                                key={fundingSource}
                                createOrder={createOrderHandler}
                                onApprove={onApproveHandler}

                                style={{
                                    layout: 'vertical',
                                    shape: 'rect',
                                    color: (fundingSource === FUNDING.PAYLATER) ? 'gold' : '',
                                }}
                            />

                        )
                    })
                }

            </PayPalScriptProvider>

        </div>
    );
};

export default PayPalComponent;