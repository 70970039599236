import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAx2UjLBUEDZp-I2OlRHGzg28I1eB8fJm4",
    authDomain: "android-ebook-322cf.firebaseapp.com",
    projectId: "android-ebook-322cf",
    storageBucket: "android-ebook-322cf.appspot.com",
    messagingSenderId: "398081450356",
    appId: "1:398081450356:web:5458375952d853147dcc21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

export { db, auth };