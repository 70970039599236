import { React, useState, useEffect } from 'react';
import '../design/book_card.css';
import PayPal from './PayPal';
import { db } from './MyFirebase';
import { ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom'

// Access the user data (Session)
const storedUser = sessionStorage.getItem('user');
const user = storedUser ? JSON.parse(storedUser) : null;

const BookCard = ({ book }) => {

    const navigate = useNavigate();

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // Month is zero-based, so add 1
        const day = now.getDate();

        const formattedDateTime = `${year}-${month}-${day}`;
        return formattedDateTime;
    };

    const [bookData, setBookData] = useState({ loading: true, isExpired: false });

    useEffect(() => {
        if (user) {

            const booksRef = ref(db, `users/${user.id}/purchasedBooks/${book.id}/expirationDate`);
            onValue(booksRef, (snapshot) => {
                const data = snapshot.val();

                if (data) {
                    const currentDate = Date.parse(getCurrentDate());
                    const expirationDate = Date.parse(data);
                    const isExpired = expirationDate < currentDate;

                    setBookData({ loading: false, isExpired });
                }
                else {
                    const isExpired = true;
                    setBookData({ loading: false, isExpired });
                }
            });
        }
    }, [user, book.id]);

    const handleButtonClick = () => {
        navigate(book.url); // Redirect to PDFViewer component
    };

    /*const booksRef = ref(db, `users/${user.id}/purchasedBooks/${book.id}/expirationDate`);
    onValue(booksRef, (snapshot) => {
        const data = snapshot.val();
        const currentDate = Date.parse(getCurrentDate());
        const expirationDate = Date.parse(data);
        setIsExpired(expirationDate < currentDate);
    });*/

    if (bookData.loading) {
        return (
            <div className="book-card">
                <img src={book.image} alt={book.title} />
                <h3>{book.title}</h3>
                <p>{book.author}</p>
                <h1>{book.price}</h1>
                <p>{book.description}</p>
                <p>Loading...</p>
                </div>
            );
    }

    return (
        <div className="book-card">
            <img src={book.image} alt={book.title} />
            <h3>{book.title}</h3>
            <p>{book.author}</p>
            <p>{book.description}</p>
            {bookData.isExpired ? (
                <div>
                    {/* Display content for expired books */}
                    {/*<p>Expired</p>*/}
                    <h1>{book.price}</h1>
                    <PayPal />
                </div>
            ) : (
                <div>
                    {/* Display content for valid books */}
                    {/*<p>Valid</p>*/}
                        <button className="paypal-button" onClick={handleButtonClick}> Show </button>
                </div>
            )}
        </div>
    );
};

export default BookCard;