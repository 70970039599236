import React, { useState, useEffect } from 'react';
import '../design/login.css'; // Import the external CSS file
import { auth  } from './MyFirebase.js'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import axios from 'axios';
import { db } from './MyFirebase';
import { ref, set } from 'firebase/database';
import { Link } from 'react-router-dom';

function SignIn() {

    const [signUpSuccess, setSignUpSuccess] = useState(null);
    const [signInSuccess, setSignInSuccess] = useState(null);
    const [signOutSuccess, setSignOutSuccess] = useState(null);
    const [userEmail, setUserEmail] = useState("");

    //creating IP state
    const [ip, setIP] = useState('');

    //creating function to load ip address from the API
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData()
    }, [])

    function handleRegistration(event) {
        // we create an event parameter and call event.preventDefault() to prevent
        // the default behavior of submitting a form(a page reload).
        event.preventDefault();
        const email = event.target.remail.value;
        const password = event.target.rpassword.value;
        const passwordConfirmation = event.target.rpasswordConfirmation.value;

        if (password !== passwordConfirmation) {
            setSignUpSuccess("Passwords don't match");
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setSignUpSuccess(`You've successfully signed up, ${userCredential.user.email}!`)
                const user = { id: auth.currentUser.uid, email: auth.currentUser.email, ip: ip };
                sessionStorage.setItem('user', JSON.stringify(user));
                writeSessionToFirebase(user, ip);
            })
            .catch((error) => {
                setSignUpSuccess(`There was an error signing up: ${error.message}!`)
                console.log(error);
            });

    }

    function handleLogin(event) {
        event.preventDefault();
        const email = event.target.lemail.value;
        const password = event.target.lpassword.value;

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setSignInSuccess(`You've successfully signed in as ${userCredential.user.email}!`)
                const user = { id: auth.currentUser.uid, email: auth.currentUser.email, ip: ip };
                sessionStorage.setItem('user', JSON.stringify(user));
                writeSessionToFirebase(user, ip);
            })
            .catch((error) => {
                setSignInSuccess(`There was an error signing in: ${error.message}!`)
                console.log(error);
            });
    }

    const handleResetPassword = async () => {

        if (userEmail === null || userEmail === "") {
            setSignInSuccess("Please enter your email");
            return;
        }

        try {
            sendPasswordResetEmail(auth, userEmail).then((a) => {
                setSignInSuccess('Password reset email sent. Check your inbox.');
            })
            .catch(function (error) {
                // Error occurred. Inspect error.code.
                setSignInSuccess('An error occurred. Please check the email address.');
                console.log(error);
            });
            
        } catch (error) {
            setSignInSuccess('An error occurred. Please check the email address.');
            console.error(error);
        }
    };

    // new function
    function LogOut() {
        signOut(auth)
            .then(function () {
                setSignOutSuccess("You have successfully signed out!");
            }).catch(function (error) {
                setSignOutSuccess(`There was an error signing out: ${error.message}!`);
            });
    }

    function writeSessionToFirebase(user, ip) {
        const userRef = ref(db, `users/${user.id}/session/`); // Reference to the 'books' node in the database

        const userSession = {
            userIP: ip
        };

        set(userRef, userSession) // This will generate a unique key and add the book data
            .then(() => {
                console.log('Session saved successfully');
                window.location.reload();
            })
            .catch((error) => {
                console.error('Error writing session:', error);
            });
    }

    return (

        <div>
            <center>
                <h1> Welcome! </h1>
                <h2> To view the books gallery and content, please sign in.</h2>
            </center>
            <div className="container-wrapper">
                <div className="container">
                    <h2>Register an Account</h2>
                    {signUpSuccess}
                    <form onSubmit={handleRegistration}>
                        <input className="input" type="email" id="remail" placeholder="Email" required />
                        <input className="input" type="password" id="rpassword" placeholder="Password" required />
                        <input className="input" type="password" id="rpasswordConfirmation" placeholder="Password confirmation" required />
                        <button className="button" type="submit">Register</button>
                    </form>
                </div>

                <div className="container">
                    <h2>Login to Your Account</h2>
                    {signInSuccess}
                    <form onSubmit={handleLogin}>
                        <input className="input" type="email" id="lemail" placeholder="Email" onChange={(e) => setUserEmail(e.target.value)} required />
                        <input className="input" type="password" id="lpassword" placeholder="Password" required />
                        <button className="button" type="submit">Login</button>
                        <Link className="forgot-password-link" to="#" onClick={handleResetPassword}>Forgot Password?</Link>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignIn;