import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import '../design/book.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useNavigate } from 'react-router-dom';
import FloatingJumpToPage from './FloatingJumpToPage';
import SignIn from './SignIn';
import { db } from './MyFirebase';
import { ref, onValue } from 'firebase/database';
import { signOut } from "firebase/auth";
import { auth } from './MyFirebase.js'
import FloatingLogout from './FloatingLogout';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const disableContextMenu = (event) => {
    event.preventDefault();
};

const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Month is zero-based, so add 1
    const day = now.getDate();

    const formattedDateTime = `${year}-${month}-${day}`;
    return formattedDateTime;
};

// Access the user data (Session)
const storedUser = sessionStorage.getItem('user');
const user = storedUser ? JSON.parse(storedUser) : null;

const PDFViewer = ({ pdfUrl, bookId }) => {

    const pageContainerRef = useRef(); // Ref to the container element

    const [jumpToPage, setJumpToPage] = useState(1);
    const numPages = 227;
    const navigate = useNavigate();

    const handleLinkClick = (event) => {
        event.preventDefault();
        const href = event.target.getAttribute('href');

        // Check if external link
        if (href && href.startsWith('http')) {
            window.open(href, '_blank');
        } else {
            navigate(href); // Navigate using useNavigate
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [scrollPosition, setScrollPosition] = useState(0);


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        // Replace 'totalPages' with the actual total number of pages in the PDF
        const totalPages = 227; // Replace with your actual total page count
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleJumpButtonClick = (jumpToPage) => {
        const pageNumber = parseInt(jumpToPage);

        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= numPages) {
            // Calculate the scroll position based on the container height and desired page number
            const containerHeight = pageContainerRef.current.clientHeight;
            const pageHeight = containerHeight / numPages;
            const scrollPosition = (pageNumber - 1) * pageHeight;
            // Scroll to the calculated position
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
    };

    // Scroll event listener and calculation
    useEffect(() => {
        const handleScroll = () => {
            const newPosition = window.scrollY;
            const newPage = calculatePageFromScrollPosition(newPosition);
            setCurrentPage(newPage);
            setScrollPosition(newPosition);
            // Update the jumpToPage state with the current page
            setJumpToPage(newPage.toString());
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // Calculate Page from Scroll Position
    const calculatePageFromScrollPosition = (scrollPosition) => {
        const containerHeight = pageContainerRef.current.clientHeight;
        const pageHeight = containerHeight / numPages;
        const currentPage = Math.floor(scrollPosition / pageHeight) + 1;
        return currentPage;
    };

    const MINUTE_MS = 300000;

    useEffect(() => {
        const interval = setInterval(() => {

            if (user) {
                const userRef = ref(db, `users/${user.id}/session/userIP`); // Reference to the 'books' node in the database
                onValue(userRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data != user.ip) {
                        LogOut();
                    }
                });
            }
            else {
                clearInterval(interval);
            }

        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    // new function
    function LogOut() {
        signOut(auth)
            .then(function () {
                console.log("You have successfully signed out!");
                sessionStorage.clear();
                window.location.reload();
            }).catch(function (error) {
                console.log(`There was an error signing out: ${error.message}!`);
            });
    }

    // Check if the book was purchased
    const [bookData, setBookData] = useState({ loading: true, isExpired: false });

    useEffect(() => {
        if (user) {
            const booksRef = ref(db, `users/${user.id}/purchasedBooks/${bookId}/expirationDate`);
            onValue(booksRef, (snapshot) => {
                const data = snapshot.val();

                if (data) {
                    const currentDate = Date.parse(getCurrentDate());
                    const expirationDate = Date.parse(data);
                    const isExpired = expirationDate < currentDate;

                    setBookData({ loading: false, isExpired });

                    if (isExpired) {
                        startRedirectionTimer();
                    }
                }
                else {
                    const isExpired = true;
                    setBookData({ loading: false, isExpired });
                    startRedirectionTimer();
                }
            });
        }
    }, [user]);

    function startRedirectionTimer() {
        // Wait for 2 seconds (2000 milliseconds)
        const timeoutId = setTimeout(() => {
            // Redirect to a specific route after 2 seconds
            navigate('../gallery');
        }, 3000);

        // Clean up the timeout if the component unmounts
        return () => {
            clearTimeout(timeoutId);
        };
    }

    // Check if the user is authenticated
    if (!user) {
        console.log('User is not authenticated');
        return (
            <React.Fragment>
                <center><h1>You must be signed in to access the book.</h1></center>
                <SignIn />
            </React.Fragment>
        )
    } else if (user) {
        console.log('User is authenticated');

        if (bookData.loading) {

            return (
                <div> Loading.. </div>
            );
        }

        return (

            <div>

                {bookData.isExpired ? (
                    <div>
                        <React.Fragment>
                            <center><h1>You need to pay for this book.</h1>
                            <h3>Redirecting...</h3></center>
                        </React.Fragment>
                    </div>
                ) :
                    (
                        <div>
                            <FloatingLogout />
                            {/* Jump to page text box and button */}
                            <FloatingJumpToPage
                                currentPage={currentPage}
                                numPages={numPages}
                                jumpToPage={jumpToPage}
                                onJumpButtonClick={handleJumpButtonClick}
                                onJumpPageInputChange={(e) => setJumpToPage(e.target.value)}
                            />

                            <div className="pdf-container" onContextMenu={disableContextMenu}
                                ref={pageContainerRef} style={{ overflow: 'hidden' }}        >

                                <Document file={pdfUrl}>
                                    {Array.from({ length: 227 }, (_, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            renderTextLayer={false}
                                            scale={2}
                                            className="page-wrapper"

                                            onClick={handleLinkClick} // Attach the link click handler
                                        />
                                    ))}
                                </Document>
                            </div>
                        </div>
                )};
                
                </div>
        );

        // NOT IN USE!!!
        return (
            <div>
                {/* Jump to page text box and button */}
                <FloatingJumpToPage
                    currentPage={currentPage}
                    numPages={numPages}
                    jumpToPage={jumpToPage}
                    onJumpButtonClick={handleJumpButtonClick}
                    onJumpPageInputChange={(e) => setJumpToPage(e.target.value)}
                />

                <div className="pdf-container" onContextMenu={disableContextMenu}
                    ref={pageContainerRef} style={{ overflow: 'hidden' }}        >

                    {

                    /*<div>
                    <button onClick={handleNextPage}>Next Page</button>
                    <button onClick={handlePreviousPage}>Previous Page</button>
                </div>

                <div class="grid-container">

                    <div class="grid-child purple">
                        Grid Column 1

                        <Document file={pdfUrl}>
                            <Page pageNumber={currentPage + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                scale={2}
                                annotationLayer
                                    />
                        </Document>
                    </div>

                    <div class="grid-child green">
                        Grid Column 2

                        <Document file={pdfUrl}>
                            <Page pageNumber={currentPage}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                scale={2}
                                    />
                        </Document>
                    </div>

                </div>*/}

                    <Document file={pdfUrl}>
                        {Array.from({ length: 227 }, (_, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                scale={3}
                                className="page-wrapper"

                                onClick={handleLinkClick} // Attach the link click handler
                            />
                        ))}
                    </Document>
                </div>
            </div>
        );
    }
};

export default PDFViewer;