import React, { useState } from 'react';
import { auth } from './MyFirebase.js'
import '../design/logout.css';

const FloatingLogout = () => {

    const [signOutSuccess, setSignOutSuccess] = useState(null);

    // new function
    function handleLogout() {
        auth.signOut()
            .then(function () {
                setSignOutSuccess("You have successfully signed out!");
                sessionStorage.removeItem('user');
                window.location.reload();

            }).catch(function (error) {
                setSignOutSuccess(`There was an error signing out: ${error.message}!`);
            });
    }

    return (
        <div className="floating-logout-box">
            <button className="logout-button" onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default FloatingLogout;
