import React from 'react';
import BookCard from './BookCard'; // Create this component
import SignIn from './SignIn';
import FloatingLogout from './FloatingLogout';

// Access the user data (Session)
const storedUser = sessionStorage.getItem('user');
const user = storedUser ? JSON.parse(storedUser) : null;

const BooksGallery = ({ books }) => {

    // Check if the user is authenticated
    if (user) {
        console.log('User is authenticated');

        return (
            <div>
                <FloatingLogout />
                <center>
                    <h1> Books Gallery </h1>
                    <div className="books-gallery">
                        {books.map((book, index) => (
                            <BookCard key={index} book={book} />
                        ))}
                    </div>
                </center>
            </div>
        );

    } else {
        console.log('User is not authenticated');

        return (
            <React.Fragment>
                <SignIn />
            </React.Fragment>
        )
    }
};

export default BooksGallery;
